<template>
  <b-modal
    ref="profile-modal"
    no-close-on-backdrop
    hide-footer
    title="แก้ไขข้อมูลส่วนตัว"
    ok-variant="success"
    ok-title="แก้ไขข้อมูลส่วนตัว"
    cancel-title="ยกเลิก"
    footer-class="justify-content-between"
    @show="resetModal"
    @hidden="resetModal"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >

      <b-form-group
        label="รหัสผู้ใช้"
      >
        <b-form-input
          v-model="input.username"
          type="text"
          disabled
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="ชื่อ"
      >
        <b-form-input
          v-model="input.firstName"
          type="text"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="นามสกุล"
      >
        <b-form-input
          v-model="input.lastName"
          type="text"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="โทรศัพท์"
      >
        <b-form-input
          v-model="input.tel"
          type="text"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="Line ID"
      >
        <b-form-input
          v-model="input.lineId"
          type="text"
        ></b-form-input>
      </b-form-group>

      <b-button type="submit" variant="primary" block pill :disabled="isProcess">{{isProcess ? 'กำลังบันทึกข้อมูล...' : 'แก้ไขข้อมูล'}}</b-button>

    </form>
  </b-modal>
</template>
<script>
import AuthService from '@/services/authService'
import Auth from '@/helpers/auth'

import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'

export default {
  name: 'EditProfileModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isProcess: false,
      input: JSON.parse(JSON.stringify(this.$store.state.userProfile))
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    }
  },
  methods: {
    resetModal() {
      this.isProcess = false
      this.input = JSON.parse(JSON.stringify(this.$store.state.userProfile))
    },
    showModal() {
      this.$refs['profile-modal'].show()
    },
    hideModal() {
      this.$refs['profile-modal'].hide()
    },
    handleSubmit() {
      this.isProcess = true
      AuthService.updateProfile(this.input)
      .then((response)=>{
        console.log(response)
        if(response.success) {
          this.$store.dispatch('reloadProfile')
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'แก้ไขข้อมูลส่วนตัวเสร็จเรียบร้อย',
          })
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        console.log(e)
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'แก้ไขข้อมูลส่วนตัวไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close')
    })
  }
}
</script>
