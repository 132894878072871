<template>
  <component v-bind:is="transformed" v-bind="$props"/>
</template>
<script>
export default {
  name: 'NewsContent',
  props: ['data'],
  computed: {
    transformed() {
      return {
        template: `<div>${this.data}</div>`,
        props: this.$options.props
      }
    }
  }
}
</script>
