<template>
  <CFooter :fixed="false" style="background-color: #0A2647; color: #FFFFFF;">
    <div class="container text-center">
      <span class="ml-1">&copy;{{new Date().getFullYear()}} www.ct-lot.com</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
