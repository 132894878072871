<template>
  <CHeaderNavItem>
    <span class="header-balance px-2">฿{{Balance}}</span>
  </CHeaderNavItem>
</template>
<script>
export default {
  computed: {
    Balance() {
      return this.$store.state.account.balance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  }
}
</script>
<style lang="scss" scoped>
.header-balance {
  background-color: #FFFFFF;
  border-radius: 10px;
}
</style>
