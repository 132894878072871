<template>
  <b-modal
    ref="password-modal"
    no-close-on-backdrop
    hide-footer
    title="เปลี่ยนรหัสผ่าน"
    ok-variant="success"
    ok-title="เปลี่ยนรหัสผ่าน"
    cancel-title="ยกเลิก"
    footer-class="justify-content-between"
    @show="resetModal"
    @hidden="resetModal"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >

      <b-form-group
        label="รหัสผ่านเดิม"
        label-for="input-old-password"
      >
        <b-form-input
          id="input-old-password"
          v-model="password.oPassword"
          type="password"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="รหัสผ่านใหม่"
        label-for="input-new-password"
      >
        <b-form-input
          id="input-new-password"
          v-model="password.nPassword"
          type="password"
          required
          :state="validPassword"
        ></b-form-input>
        <small class="form-text text-muted">
          <ul class="pl-3">
            <li>ความยาวอย่างน้อย 8 ตัวอักษร <i class="fas fa-check text-success" v-if="pwLength"></i></li>
            <li>อักษรภาษาอังกฤษพิมพ์ใหญ่ อย่างน้อย 1 ตัว <i class="fas fa-check text-success" v-if="pwUppercase"></i></li>
            <li>อักษรภาษาอังกฤษพิมพ์เล็ก อย่างน้อย 1 ตัว <i class="fas fa-check text-success" v-if="pwLowercase"></i></li>
            <li>ตัวเลข อย่างน้อย 1 ตัว <i class="fas fa-check text-success" v-if="pwNumber"></i></li>
            <li>อักษรพิเศษ อย่างน้อย 1 ตัว <i class="fas fa-check text-success" v-if="pwSymbol"></i></li>
          </ul>
        </small>
      </b-form-group>

      <b-form-group
        label="ยืนยันรหัสผ่าน"
        label-for="input-confirm-password"
      >
        <b-form-input
          id="input-confirm-password"
          v-model="password.cPassword"
          type="password"
          required
        ></b-form-input>
      </b-form-group>

      <b-button type="submit" variant="danger" block pill :disabled="!validPassword || isProcess">{{isProcess ? 'กำลังบันทึกรหัสผ่าน...' : 'เปลี่ยนรหัสผ่าน'}}</b-button>

    </form>
  </b-modal>
</template>
<script>
import AuthService from '@/services/authService'
import Auth from '@/helpers/auth'
import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'
import { passwordStrength } from 'check-password-strength'

export default {
  name: 'ChangePasswordModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isProcess: false,
      password: {
        oPassword: '',
        nPassword: '',
        cPassword: ''
      }
    }
  },
  computed: {
    pwStrength() {
      return passwordStrength(this.password.nPassword)
    },
    pwLength() {
      return this.pwStrength.length >= 8
    },
    pwLowercase() {
      return this.pwStrength.contains.includes('lowercase')
    },
    pwUppercase() {
      return this.pwStrength.contains.includes('uppercase')
    },
    pwNumber() {
      return this.pwStrength.contains.includes('number')
    },
    pwSymbol() {
      return this.pwStrength.contains.includes('symbol')
    },
    validPassword() {
      if(!this.password.nPassword)
        return null

      return this.pwLength && this.pwLowercase && this.pwUppercase && this.pwNumber && this.pwSymbol
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    }
  },
  methods: {
    resetModal() {
      this.isProcess = false
      this.password = {
        oPassword: '',
        nPassword: '',
        cPassword: ''
      }
    },
    showModal() {
      this.$refs['password-modal'].show()
    },
    hideModal() {
      this.$refs['password-modal'].hide()
    },
    handleSubmit() {

      if(!this.validPassword) {
        Swal.fire({
          text: 'รูปแบบรหัสผ่านไม่ถูกต้อง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        return
      }else
      if(this.password.nPassword !== this.password.cPassword) {
        Swal.fire({
          text: 'ยืนยันรหัสผ่านไม่ถูกต้อง',
          icon: 'error',
          confirmButtonText: 'OK'
        }).then((res)=>{
          this.password.cPassword = ''
        })
        return
      }

      const self = this
      this.isProcess = true
      AuthService.changePassword(this.password)
      .then((response)=>{
        console.log(response)
        if(response.success) {
          self.$nextTick(() => {
            self.$refs['password-modal'].hide()
          })
          Swal.fire({
            title: 'สำเร็จ!',
            text: 'เปลี่ยนรหัสผ่านเสร็จเรียบร้อย กรุณาเข้าสู่ระบบใหม่อีกครั้ง',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then((result)=>{
            Auth.logout()
          })
          return
        }else{
          throw new Error()
        }
      })
      .catch((e)=>{
        console.log(e)
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'เปลี่ยนรหัสผ่านไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close')
    })
  }
}
</script>
